const router = [
  {
    name: 'app.root',
    abstract: true,
    template: '<linda-app linda-zendesk ></linda-app>',
  },
  {
    name: 'app.root.paymentcb',
    url: '/payment/:act?order_id&identifier',
    template: '<linda-payment-callback />',
  },
  {
    name: 'app.root.withdraw-paymentcb-success',
    url: '/withdraw-payment/:act',
    template: '<linda-payment-callback />'
  },
  {
    name: 'app.root.game',
    abstract: true,
    template: '<linda-game-page scroll-to-top=""></linda-game-page>',
    gamePage: true,
  },
  {
    name: 'app.root.claw_games',
    abstract: true,
    private: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.claw_games.gamepage',
    url: '/machine/:name',
    template: '<batman-view-layer1 />',
  },
  {
    name: 'app.root.game.real',
    url: '/play/:name',
    template: '',
    private: true,
    params: {
      mode: 'real',
    },
  },
  {
    name: 'app.root.game.demo',
    url: '/game/:name',
    template: '<linda-demo-game-footer  game="game"></linda-demo-game-footer>',
    params: {
      mode: 'demo',
    },
  },
  {
    name: 'app.root.h1',
    abstract: true,
    template: '<linda-view-layer-two></linda-view-layer-two>',
  },
  {
    name: 'app.root.h1.home',
    url: '/',
    template: '<linda-homepage></linda-homepage>',
  },
  {
    name: 'app.root.h1.casino',
    url: '/casino',
    template: '<linda-view-layer-six></linda-view-layer-six>',
  },
  //LAYOUT ( sidebar + header + footer )
  {
    name: 'app.root.l1',
    abstract: true,
    template: '<linda-view-layer-one></linda-view-layer-one>',
  },
  // GAME HALL
  {
    name: 'app.root.l1.gh',
    abstract: true,
    template: '<linda-casino-section></linda-casino-section>',
  },
  {
    name: 'app.root.l1.gh.live',
    url: '/games/live-casino',
    template: '<batman-game-hall1 scroll-to-top ></batman-game-hall1>',
    params: {
      name: 'live-casino',
    },
  },
  {
    name: 'app.root.l1.gh.casino',
    abstract: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.l1.gh.casino.gamehall',
    url: '/games',
    template: '<batman-game-hall3 scroll-to-top></batman-game-hall3>',
  },
  {
    name: 'app.root.l1.gh.casino.collection',
    url: '/games/:name',
    template: '<batman-game-hall1 scroll-to-top></batman-game-hall1>',
  },
  {
    name: 'app.root.l1.gh.casino.provider',
    url: '/casino-providers/:name',
    template: '<batman-game-hall2 scroll-to-top></batman-game-hall2>',
  },
  {
    name: 'app.root.l1.gh.casino.favourites',
    url: '/favourites',
    template: '<batman-game-box-favourites1 scroll-to-top></batman-game-box-favourites1>',
  },
  {
    name: 'app.root.l1.gh.casino.lastplayed',
    url: '/last-played',
    template: '<batman-recently-played-list1 scroll-to-top></batman-recently-played-list1>',
  },
  // Paynplay
  {
    name: 'app.root.l1.paynplay',
    url: '/fast-deposit',
    template:
      '<linda-zimpler-paynplay-page scroll-to-top class="full-screen trustly-wrap"></linda-zimpler-paynplay-page>',
  },
  // PROFILE
  {
    name: 'app.root.l1.account',
    abstract: true,
    private: true,
    template: '<linda-profile-page></linda-profile-page>',
  },
  {
    name: 'app.root.l1.account.dashboard',
    url: '/account',
    template: '<linda-profile-staff></linda-profile-staff>',
  },
  {
    name: 'app.root.l1.account.bonuses',
    url: '/account/bonuses',
    template: '<linda-profile-bonus-list></linda-profile-bonus-list>',
  },
  {
    name: 'app.root.l1.account.exchanger',
    url: '/account/exchanger',
    template: '<linda-lp-exchange-form></linda-lp-exchange-form>',
  },
  {
    name: 'app.root.l1.account.info',
    url: '/account/info',
    template: '<linda-profile-settings></linda-profile-settings>',
  },
  {
    name: 'app.root.l1.account.history',
    url: '/account/balance-history',
    template: '<linda-cashbox-balance-information></linda-cashbox-balance-information>',
  },
  //Identify verification profile page
  {
    name: 'app.root.l1.account.verification',
    url: '/account/verification',
    template: '<dvs-page scroll-to-top></dvs-page>',
    params: {
      page: 'verification',
    },
  },
  // Pages
  {
    name: 'app.root.l1.vip',
    url: '/vip',
    template: '<linda-vip scroll-to-top></linda-vip>',
  },
  //tournaments
  {
    name: 'app.root.l1.tournament',
    abstract: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.l1.tournament.list',
    url: '/tournaments',
    template: '<batman-tournament-list1 players="true" competitors="30" scroll-to-top></batman-tournament-list1>',
  },
  {
    name: 'app.root.l1.tournament.page',
    url: '/tournaments/:name',
    template: '<linda-tournament-page scroll-to-top tournament="$resolve.tournament"></linda-tournament-page>',
    onEnter: [
      "tournamentItemResolver",
      function (tournamentItemResolver) {
        tournamentItemResolver.resolve();
      },
    ],
  },
  {
    name: 'app.root.l1.tournament.sport',
    url: '/sport-tournament',
    template: '<batman-view-layer3 scroll-to-top></batman-view-layer3>',
  },
  {
    name: 'app.root.l1.tournament.euro2024',
    url: '/tournaments/euro2024',
    template: '<batman-view-layer4 scroll-to-top></batman-view-layer4>',
  },
  //promotions
  {
    name: 'app.root.l1.promo',
    abstract: true,
    template: '<linda-view-layer-three></linda-view-layer-three>',
  },
  {
    name: 'app.root.l1.promo.list',
    url: '/promotions',
    template: '<linda-promos scroll-to-top></linda-promos>',
  },
  {
    name: 'app.root.l1.promo.item',
    url: '/promotions/:name',
    template: '<linda-promo-page scroll-to-top promo="$resolve.promo"></linda-promo-page>',
    resolve: {
      promo: [
      "promoItemResolver",
        function (promoItemResolver) {
          return promoItemResolver.resolve();
        },
      ],
    },
  },
  //sport
  {
    name: 'app.root.l1.sportpage',
    abstract: true,
    template: '<linda-sport-page></linda-sport-page>',
  },
  {
    name: 'app.root.l1.sportpage.prelive',
    url: '/sport?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'prelive',
    },
  },
  {
    name: 'app.root.l1.sportpage.live',
    url: '/sport/live?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'live',
    },
  },
  {
    name: 'app.root.l1.sportpage.virtual',
    url: '/sport/virtual?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'vflm',
    },
  },
  {
    name: 'app.root.l1.sportpage.mybets',
    url: '/sport/mybets',
    template: '',
    params: {
      page: 'mybets',
    },
  },
  //Shop page
  {
    name: 'app.root.l1.shop',
    url: '/shop',
    template: '<linda-shop-page scroll-to-top></linda-shop-page>',
    params: {
      page: 'shop-page',
    },
  },
  // Info pages
  {
    name: 'app.root.l1.info',
    abstract: true,
    template: '<linda-view-layer-four scroll-to-top></linda-view-layer-four>',
  },
  {
    name: 'app.root.l1.info.contact',
    url: '/contact-us',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'contact-us',
    },
  },
  {
    name: 'app.root.l1.info.terms',
    url: '/rules',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'rules',
    },
  },
  {
    name: 'app.root.l1.info.responsible-gaming',
    url: '/responsible-gaming',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'responsible-gaming',
    },
  },
  {
    name: 'app.root.l1.info.privacy-policy',
    url: '/privacy-notice',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'privacy-notice',
    },
  },
  {
    name: 'app.root.l1.info.cookies-policy',
    url: '/cookies-notice',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'cookies-notice',
    },
  },
  {
    name: 'app.root.l1.info.about',
    url: '/about-us',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'about',
    },
  },
  {
    name: 'app.root.l1.info.sitemap',
    url: '/sitemap',
    template: '<linda-sitemap scroll-to-top></linda-sitemap>',
    params: {
      name: 'sitemap',
    },
  },
  {
    name: 'app.root.l1.info.faq',
    url: '/faq',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'faq',
    },
  },
  {
    name: 'app.root.missedData',
    url: '/missed-data',
    template: '<linda-missed-data-form><linda-missed-data-form/>',
  },
  {
    name: 'app.root.l1.404',
    url: '/404',
    template: '<not-found></not-found>',
  },
  {
    name: 'app.root.seasons',
    url: '/seasons/summer',
    private: true,
    template: '<summer-promo-wrapper scroll-to-top="" class="seasons-promo-page"></summer-promo-wrapper>',
  },
  {
    name: 'app.root.summer-game',
    url: '/seasons-summer-game',
    private: true,
    template: `<summer-promo-page scroll-to-top=""></summer-promo-page>`,
  },
  {
    name: 'app.root.l1.lamborghini',
    url: '/lamborghini-promo',
    template: '<linda-view-layer-seven></linda-view-layer-seven>',
  },
  {
    name: 'app.root.l1.ferrari',
    url: '/ferrari-promo',
    template: '<batman-car-promo class="ferrari-promotion"></batman-car-promo>',
  },
];

export { router };
